import React from 'react';
import classNames from 'classnames';
import { ReactComponent as Logo } from '../../assets/images/new-logo.png'
import classes from './Loading.module.css'

function Loading({ inline, ...props}) {
    return (
        <div className={classNames(
          classes.loadingWrapper,
          inline && classes.inline
        )}>
            <img src={Logo} className={classes.loadingLogo}/>
        </div>
    );
}

export default Loading;