import React, {Suspense} from 'react';
import {Route, Switch} from "react-router";
import {Header} from "../../components/Header";
import Loading from "../../components/Loader/Loading";

const Classes = React.lazy(() => import('./Classes'));
const CreateClass = React.lazy(() => import('./CreateClass'));
const ClassAttendees = React.lazy(() => import('./ClassAttendees'));
const ClassCategories = React.lazy(() => import('./ClassCategories'));

const ClassesIndex = ({ match }) => {
  const { path } = match
  return (
    <>
      <Header activeLink={'Class'} />
      <Suspense fallback={<Loading inline/>}>
        <Switch>
          <Route path={path} component={Classes} exact />
          <Route path={`${path}/categories`} component={ClassCategories}/>
            <Route path={`${path}/:classId/attendees`} component={ClassAttendees}/>
            <Route path={`${path}/:type/:classId`} exact component={CreateClass}/>
          <Route path={`${path}/:type`} exact component={CreateClass} />
        </Switch>
      </Suspense>
    </>
  )
};

export default ClassesIndex;