import React, {Component} from 'react';
import classes from './Login.module.css'
import { TextInput } from "../../components/form/TextInput";
import { Checkbox } from "../../components/form/Checkbox";
import {Link, Redirect} from "react-router-dom";
import {PrimaryButton} from "../../components/Button";
import {fetchHelper} from "../../App";
import {withUserDetails} from "../../hoc/WithUserDetails";
import StatusCard, {StatusCardStatuses} from "../../components/form/StatusCard";
import Logo from "../../assets/images/new-logo.png";
import {configureAuthentication, makeCachedRequest, makeRequest} from "../../api/api";
import {getErrorMessage} from "../../utils/functions";
import Auth from '../../utils/Auth';

class Login extends Component {
    state = {
        email: '',
        password: '',
        storeData: false,
        loggingIn: false,
        redirect: false,
        redirectLink: '',
        status: {
            status: StatusCardStatuses.INACTIVE,
            text: ''
        }
    };


    validate = () => {
        const {email,password} = this.state;
        if(email === '' || password === ''){
            this.setStatus('Please fill all fields.',StatusCardStatuses.ERROR);
            return false
        }
        return true
    };

    setStatus = (text,status) => {
        this.setState({
            status: {
                status,
                text
            },
            loggingIn: false
        })
    };

    logIn = () => {
        this.setState({
            loggingIn: true
        });
        const {isAdmin,logIn} = this.props;
        const {email,password,storeData} = this.state;
        if(this.validate()){
                fetchHelper(`Users/Authenticate`, 'POST', {email: email.trim(), password}, {'Authorization': `Bearer ${Auth.getToken()}`})
                    .then(res => res.json())
                    .then(token => {
                        //@todo
                        //This is terrible and should be changed. The data format of the apis should be changed to a more appropriate pattern
                        if(/invalid|incorrect|\s+/gi.test(token) === false){
                            configureAuthentication(token);
                            fetchHelper.authorization = `Bearer ${token}`;
                            Promise.all([
                                makeCachedRequest(`Users/${email.trim()}`).then(res => res.data),
                                makeCachedRequest(`Users/GetDp/${email.trim()}`).then(res => res.data).catch(err => {
                                    if (err.response && err.response.status === 404) {
                                        return null
                                    }

                                    return Promise.reject(getErrorMessage(err))
                                })
                            ]).then(([user,thumbnail]) => {
                                logIn({...user,thumbnail},storeData);
                                this.setStatus('Success. Redirecting...',StatusCardStatuses.SUCCESS);
                                this.setState({
                                    redirectLink: isAdmin() ? '/dashboard' : '/courses'
                                });
                                setTimeout(() => {
                                    this.setState({
                                        redirect: true
                                    })
                                },2000)
                            })
                        }else{
                            this.setStatus('Kindly verify your details and try again',StatusCardStatuses.ERROR);
                        }
                    })
                    .catch(() => {
                        this.setState({
                            status: {
                                status: StatusCardStatuses.ERROR,
                                text: 'Kindly check your internet connection and try again'
                            },
                            loggingIn: false
                        })
                    })
            }
    };

    render() {
        return (
            <>
                {
                    this.state.redirect && <Redirect to={this.state.redirectLink}/>
                }
                <div className={classes.container}>
                    <div className={[
                        classes.form,
                        classes.loginForm
                    ].join(' ')}>
                        <img src={Logo} className={classes.formIcon}/>
                        <p className={classes.formHeader}>
                            Log In
                        </p>
                        <StatusCard
                            status={this.state.status.status}
                        >
                            {this.state.status.text}
                        </StatusCard>
                        <div className={classes.formGroup}>
                            <TextInput
                                value={this.state.email}
                                label={'Email address'}
                                placeholder={'Enter your email address'}
                                onChange={e => this.setState({email: e.target.value})}
                                className={classes.formInput}
                            />
                        </div>
                        <div className={classes.formGroup}>
                            <TextInput
                                value={this.state.password}
                                label={'Password'}
                                placeholder={'Enter your password'}
                                onChange={e => this.setState({password: e.target.value})}
                                className={classes.formInput}
                                type={'password'}
                            />
                        </div>
                        <div className={classes.formRow}>
                            <Checkbox
                                label={'Keep me logged in'}
                                checked={this.state.storeData}
                                onToggle={() => this.setState({storeData: !this.state.storeData})}
                            />
                            <Link
                                to={'./forgot-password'}
                                className={classes.loginFormForgotPasswordLink}

                            >
                                Forgot your Password?
                            </Link>
                        </div>
                        <PrimaryButton
                            className={classes.formButton}
                            loaderButton
                            loading={this.state.loggingIn}
                            onClick={this.logIn}
                            disabled={this.state.loggingIn}
                        >
                            Log in
                        </PrimaryButton>
                    </div>
                    <div className={[
                        classes.image,
                        classes.loginImage
                    ].join(' ')}>

                    </div>
                </div>
            </>
        );
    }
}

export default withUserDetails(Login);