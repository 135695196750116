import React, { Suspense, lazy } from 'react'
import {Header} from "../../components/Header";
import Loading from "../../components/Loader/Loading";
import {Route, Switch} from "react-router";

const Courses = lazy(() => import('./Courses'));
const CreateCourse = lazy(() => import('./CreateCourse'));
const EditCourse = lazy(() => import('./EditCourse'));
const CourseStudents = lazy(() => import('./CourseStudents'));
const CourseCurriculum = lazy(() => import('./CourseCurriculum'));
const CourseLectureContent = lazy(() => import('./CourseLectureContent'));
const CourseCategories = lazy(() => import('./CourseCategories'));


const CoursesIndex = ({ match }) => {
  const { path } = match
  return (
    <>
      <Header activeLink={'Courses'} />
      <Suspense fallback={<Loading inline/>}>
        <Switch>
          <Route path={path} component={Courses} exact/>
          <Route path={`${path}/categories`} component={CourseCategories}/>
          <Route path={`${path}/new`} component={CreateCourse}/>
          <Route path={`${path}/edit/:courseId`} component={EditCourse}/>
          <Route path={`${path}/:courseId/students`} component={CourseStudents}/>
          <Route path={`${path}/:courseId/lectures`} component={CourseCurriculum} exact/>
          <Route path={`${path}/:courseId/lectures/:lectureId/edit`} component={CourseLectureContent}/>
        </Switch>
      </Suspense>
    </>
  )
}

export default CoursesIndex