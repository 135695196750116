import {API_LINK, CacheCategories, fetchHelper, getImageURL} from "../App";
import moment from "moment";
import {makeCachedRequest, makeRequest} from "../api/api";
import {fetchItemsData} from "../api/apiFunctions";

export const inviteUser = (values, category = 'admin') => {
    const links = {
        admin: {
            name: 'Users/InviteAdminWithName',
            withoutname: 'Users/InviteAdmin'
        },
        author: {
            name: 'Users/InviteAuthorWithName',
            withoutname: 'Users/InviteAuthor'
        }
    };

    let suffix = '';
    suffix += `/${values.email}`;
    let apiRoute = links[category].withoutname;
    if (values.name) {
        const [firstname, lastname] = values.name.split(' ');
        suffix += `/${firstname}/${lastname}`;
        apiRoute = links[category].name
    }

    if (category === 'author') {
        suffix += `/${values.isprivileged}`
    }

    CacheCategories.Users = true
    return makeRequest.post(`${apiRoute}${suffix}`).then(res => res.data);
};

export const getUsers = async ({
                                   pageNo,
                                   pageSize = 10,
                                   sort = 'name_asc',
                                   type,
                                   query
                               }) => {

    const response = await makeCachedRequest(`Admin`, {
        params: {
            pageNo,
            pageSize,
            sort,
            type,
            query
        }
    })

    const {data, paging} = response.data

    return {
        users: data.map(formatUser),
        paging
    }
};

export const formatUser = (user) => {
    user.lastLoginFormatted = user.lastlogin === '0001-01-01T00:00:00' ? 'Never' : moment(user.lastlogin).fromNow();
    user.dateAddedFormatted = moment(user.dateadded).fromNow();
    user.name = (user.firstname || user.lastname) ? `${user.firstname || ''} ${user.lastname || ''}` : '';
    return user;
};

export const convertToFormatted = (
    amount,
    withSymbol = false,
    stripDecimals = false
) => {
    let formatted = new Intl.NumberFormat('en-NG', {
        currency: 'NGN',
        style: 'currency',
    }).format(amount)
    if (!withSymbol) formatted = formatted.substr(withSymbol ? 0 : 1)
    if (stripDecimals)
        formatted = formatted.substring(0, formatted.indexOf('.'))
    return formatted
}

export const getUserDetails = (type, email) => {
    switch (type) {
        case 'student':
        case 'business': {
            return makeCachedRequest(`Customer/${email}`).then(res => res.data).then(user => {
                if (user.accountcategory === 'Business') {
                    user.usertype = 'Business'
                } else {
                    user.usertype = user.accounttype
                }
                return user;
            });
        }
        case 'author':
        case 'admin':
            return makeCachedRequest(`Users/${email}`).then(res => res.data).then(user => {
                return {
                    ...user,
                    joindate: user.dateadded,
                    usertype: user.role
                };
            });
        default:
            break;
    }
};

export const getCategories = () => {
    return makeCachedRequest(`Coursecategory`).then(res => res.data).then(categories => {
        const formattedCategories = categories.map(({name, catid: id}) => ({
            name,
            id
        }));
        const categoriesObject = formattedCategories.reduce((categoriesObject, {name, id}) => ({
            ...categoriesObject,
            [id]: {
                name,
                id
            }
        }), {});
        return [formattedCategories, categoriesObject];
    });
};

export const processUsers = (users) => users.map(student => {
    const studentProcessed = {};
    studentProcessed.status = student.status;
    const name = (student.firstname || '') + ' ' + (student.lastname || '');
    studentProcessed.name = name.trim() || 'No Name';
    studentProcessed.email = student.email;
    studentProcessed.registered = moment(student.joindate).fromNow();
    studentProcessed.joindate = student.joindate;
    studentProcessed.phonenumber = student.phonenumber || 'n/a';
    if (student.accountcategory === 'Business') {
        studentProcessed.type = 'Business'
    } else {
        studentProcessed.type = student.accounttype || 'paid'
    }
    return studentProcessed;
});

export const getUser = email => {
    return Promise.all([
        makeCachedRequest(`Users/${email}`).then(res => res.data),
        makeCachedRequest(`Users/GetDp/${email}`).then(res => res.data).catch(err => {
            if (err.response && err.response.status === 404) {
                return null
            } else {
                return !(err.response && err.response.status === 404) && Promise.reject()
            }
        })
    ]).then(([user, thumbnail]) => ({...user, thumbnail}))
};


export const getAll = (role) => {
    return makeCachedRequest(`Users/GetAllByRole/${role}`).then(res => res.data).then((users) => {
        users = users.map(formatUser).map(({name, email, lastLoginFormatted, phonenumber, dateAddedFormatted}) => ({
            name: name.trim() || 'No Name',
            email,
            lastLoginFormatted,
            dateAddedFormatted,
            phonenumber: phonenumber || "n/a"
        }))
        return users
    })
};

export const fetchPost = postId => {
    return makeCachedRequest(`Blogpost/${postId}`)
        .then(res => res.data)
        .then(post => {
            return getUser(post.author)
                .then(user => ({
                    ...post,
                    authorDetails: user
                }));
        })
        .then(post => {
            return makeCachedRequest(`Blogpost/GetThumbnail/${post.postid}`)
              .then(res => res.data)
              .then(path => {
                  return ({
                      ...post,
                      thumbURL: !!path ? getImageURL(path) : null
                  })
              }).catch(err => {
                  if (err.response && err.response.status === 404) {
                      return ({
                        ...post,
                        thumbURL: null
                    })
                  }
              })
        })
}

export const uploadPostThumbnail = (postId, thumbnailFile) => {
    const fd = new FormData()
    fd.set('file', thumbnailFile)
    return makeRequest.post(`Blogpost/ChangeThumbnail/${postId}`, fd)
      .then(() => {
          CacheCategories.Blogpost = true
          return makeCachedRequest(`Blogpost/GetThumbnail/${postId}`)
      }).then(res => res.data)
}
const _formatDateForAPI = date => moment(date).format("YYYY-MM-DD");
export const ReportableItemTypes = Object.freeze({
    Course: "course",
    Class: "class",
    Subscription: "subscription"
});

export const fetchEarningsByDate = async ({startDate, endDate, paginationQuery}) => {
    const dateParams = `${_formatDateForAPI(startDate)}/${_formatDateForAPI(endDate)}`;
    const response = await makeCachedRequest(`PaymentLog/EarningsByDate/${dateParams}?${paginationQuery}`)
    let {data: payments, paging} = response.data;

    const transformedPayments = await transformPayments(payments)

    return {
        payments: transformedPayments,
        paging
    }
};

export const fetchEarningsByType = async ({startDate, endDate, type = ReportableItemTypes.Course}) => {
    const dateParams = `${_formatDateForAPI(startDate)}/${_formatDateForAPI(endDate)}`;
    const response = await makeCachedRequest(`PaymentLog/DateEarningByType/${type}/${dateParams}`)

    return response.data
}

export const transformPayments = async (payments) => {
    const itemsData = await Promise.all(
        payments.map(({items}) => fetchItemsData(items))
    )

    payments = payments.map((payment, paymentIndex) => ({
        ...payment,
        items: payment.items.map((item, itemIndex) => ({
            ...item,
            data: itemsData[paymentIndex][itemIndex] || {},
        })),
    }))

    return payments
}

export const transformEarnings = (earnings) => {
    return earnings.map(earning => ({
        refno: earning.reference,
        date: earning.purchaseTime,
        price: earning.totalAmount + earning.loyaltyPoint,
        earnings: earning.totalAmount,
        items: earning.items.map(({data = {}}) => data.title)
    }))
}
export const mergeEarningsArrays = (...earningsArray) => {
    return earningsArray
        .reduce(
            (acc, arr) => acc.concat(arr),
            []
        )
        .sort(
            (a, b) => a.date - b.date
        )
}

export const filterStudents = (query, students) => students.filter(({name}) => name.toLowerCase().startsWith(query.toLowerCase()));

export const sort = (sortObj, students) => {
    const {id, mode} = sortObj;
    if (id === 'name') {
        const sorted = students.sort(({name: nameA}, {name: nameB}) => {
            if (nameA.toLowerCase() < nameB.toLowerCase()) {
                return -1
            } else if (nameA.toLowerCase() > nameB.toLowerCase()) {
                return 1
            } else {
                return 0
            }
        });
        return mode === 'asc' ? sorted : sorted.reverse();
    } else if (id === 'date') {
        const sorted = students.sort(({joindate: joindateA}, {joindate: joindateB}) => {
            const secondsA = new Date(joindateA).getTime();
            const secondsB = new Date(joindateB).getTime();
            if (secondsA > secondsB) {
                return -1
            } else if (secondsA < secondsB) {
                return 1
            } else {
                return 0
            }
        });
        return mode === 'asc' ? sorted.reverse() : sorted
    }
};

export function isNetworkError(error) {
    return (
        error.name === 'NetworkError' ||
        error.message.toLowerCase().includes('network') ||
        error.message.toLowerCase().includes('timeout') ||
        !error.status ||
        (!!error && error.response && !error.response.status)
    )
}

export const getErrorMessage = (error, defaultError = "Something went wrong, we're not sure what. Our team is working on it.") => {
    if (error.response && error.response.data) {
        return error.response.data
    } else {
        if (isNetworkError(error))
            return 'A network error occurred. Kindly check your internet connection'
        else
            return defaultError
    }
}

export const getFullName = ({firstname, lastname}) => {
    const fullName = (firstname || '') + ' ' + (lastname || '');
    return fullName.trim() || 'No Name'
}

//due to limited browser support, opted to the use the helper functiion below which relies on regex... instead of the rather simpler "Number((number)?.toFixed(1)).toLocaleString()".
/**
 *
 * @param {*} number //the number being formatted as currency
 * @param {*} lengthOfDecimal  //the number of trailing zeros after the period e.g if lengthOfDecimal is 2, we have .00
 * @param {*} lengthOfSections for large numbers (millions, or thousands) we can break into sections... e.g 124,243,234,
 */
export const formatNumberAsCurrency = (number, lengthOfDecimal, lengthOfSections) => {
    var re =
      '\\d(?=(\\d{' + (lengthOfSections || 3) + '})+' + (lengthOfDecimal > 0 ? '\\.' : '$') + ')';
    return number?.toFixed(Math.max(0, ~~lengthOfDecimal)).replace(new RegExp(re, 'g'), '$&,');
};

export const renderCurrency = (currency) => `₦${formatNumberAsCurrency(currency, 2)}`;