import React from 'react';
import classes from './StatusCard.module.css'

export const StatusCardStatuses = Object.freeze({
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    INACTIVE: 'INACTIVE'
});

function StatusCard({status,className = '',children}) {
    return (
        <div className={[
            classes.statusCard,
            status === StatusCardStatuses.SUCCESS && classes.statusCardSuccess,
            status === StatusCardStatuses.ERROR && classes.statusCardError,
            className
        ].join(' ')}>
            {children}
        </div>
    );
}

export default StatusCard;