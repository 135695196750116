import React, {createContext, useCallback, useContext, useMemo, useRef} from 'react'

export const BlogEditorContext = createContext({});

export const BlogEditorProvider = ({ children }) => {
  // a key => value map of postId => postContent
  const postDataRef = useRef({})
  const addPost = useCallback((id, data) => {
    postDataRef.current = {
      ...postDataRef.current,
      [id]: data
    }
  }, []);
  const updatePost = useCallback((id, data) => {
    postDataRef.current = {
      ...postDataRef.current,
      [id]: {
        // This will update only the provided attributes of the post
        ...postDataRef.current[id],
        ...data
      }
    }
  }, []);
  const deletePost = useCallback((id) => {
    delete postDataRef.current[id]
  }, []);
  const getPost = useCallback(id => postDataRef.current[id], []);
  const contextValue = useMemo(() => ({
    postData: postDataRef.current,
    addPost,
    updatePost,
    deletePost,
    getPost
  }), []);
  return (
    <BlogEditorContext.Provider value={contextValue}>
      {children}
    </BlogEditorContext.Provider>
  )
};

export const useBlogEditor = () => useContext(BlogEditorContext);

export const BlogEditorConsumer = BlogEditorContext.Consumer;

export const withBlogEditor = (Component) => {
  const WrappedComponent = function (props) {
    return (
      <BlogEditorConsumer>
        {blogEditor => (
          <Component blogEditor={blogEditor} {...props} />
        )}
      </BlogEditorConsumer>
    )
  }
  WrappedComponent.displayName = `withBlogEditor(${Component.displayName || Component.name})`
  return WrappedComponent
}