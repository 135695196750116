import React, {forwardRef} from 'react'
import classNames from 'classnames'

import './TextInput.css'

export const TextInput = forwardRef(({ value, label = null, placeholder = '', error = null, onChange, className, ...props }, ref) => (
  <div className={classNames('TextInput', className)}>
    {label && <label className="TextInput__label" htmlFor={label}>{label}</label>}
    <input
      className="TextInput__input"
      id={label}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      ref={ref}
      {...props}
    />
    {error && <span className={'TextInput__error'}>{error}</span>}
  </div>
));