import React from 'react';
import {Redirect} from "react-router";
import {withUserDetails} from "../../hoc/WithUserDetails";
import Loading from "../Loader/Loading";

function AuthCheck({isStored,isLoggedIn,children}) {
    const stored = isStored();
    if (!isLoggedIn()) {
        if (typeof stored !== 'boolean') {
            return <Loading/>
        } else {
            return (<Redirect to={'/auth/login'}/>)
        }
    }else{
        return (
            children
        );
    }
}

export default withUserDetails(AuthCheck);