import React from 'react'
import classes from './Error404.module.css'
import {PrimaryButton} from "../../components/Button";

const Error404 = ({ history }) => {
  const goBack = () => {
    history.go(-1)
  }
  return (
    <div className={classes.container}>
      <h1 className={classes.title}>404. Not Found.</h1>
      <p className={classes.subtitle}>Seems like you're trying to go outside our defined reality. The laws of physics disallow that.</p>
      <PrimaryButton className={classes.action} onClick={goBack}>Go Back</PrimaryButton>
    </div>
  )
}

export default Error404