import React, {Component, Suspense, lazy} from 'react';
import {Route, Switch} from "react-router";
import {fetchHelper} from "../../App";
import {convertToFormatted} from "../../utils/functions";
import {SalesContext} from "../../Contexts/contexts";
import {Header} from "../../components/Header";
import Loading from "../../components/Loader/Loading";
import {makeCachedRequest} from "../../api/api";

const Purchases = lazy(() => import('./Purchases/Purchases'))
const Subscriptions = lazy(() => import('./Subscriptions/Subscriptions'))
const Reports = lazy(() => import('./Reports/Reports'))
const TransactionDetails = lazy(() => import('./TransactionDetails/TransactionDetails'))

class SalesIndex extends Component {
    componentDidMount() {
        // Trying to avoid pulling the hefty moment into the main bundle
        import("moment")
          .then(({ default: moment }) => {
              makeCachedRequest(`Paymentlog/GetByType/purchase?pageNo=1&pageSize=10`)
                  .then(res => res.data)
                  .then(({data: paymentlog, paging}) => {
                      paymentlog = paymentlog.map(log => {
                          log.earnings = `${convertToFormatted(log.cashamount, true)}`;
                          log.price = `${convertToFormatted(log.cashamount + log.loyalitypoints, true)}`;
                          return log
                      });
                      this.setState({
                          purchases: {
                              purchases: {
                                  1: paymentlog
                              },
                              paging
                          }
                      })
                  });
              makeCachedRequest(`Paymentlog/GetByType/subscription?pageNo=1&pageSize=10`).then(res => res.data).then(({data: paymentlog, paging}) => {
                  paymentlog = paymentlog.map(log => {
                      const cycleStart = `${moment(log.paymentdate).format('Do MMMM')}`;
                      let numMonths;
                      switch (log.cycle) {
                          case 'Monthly':
                              numMonths = 1;
                              break;
                          case 'Quarterly':
                              numMonths = 4;
                              break;
                          case 'Annually':
                              numMonths = 12;
                              break;
                          default:
                              break;
                      }
                      const cycleEnd = `${moment(log.paymentdate).add(numMonths,'M').format('Do MMMM')}`;
                      log.cycleFormatted = `${cycleStart} - ${cycleEnd}`;
                      return log
                  });
                  this.setState({
                      subscriptions: {
                          subscriptions: {
                              1: paymentlog
                          },
                          paging
                      }
                  });
              })
          })
    }

    addRecord = (category,pageNo,data,paging = this.state[category][category].paging) => {
        //I'm sorry
        if(this.state[category][category][pageNo] === undefined || this.state[category][category][pageNo].length === 0){
            this.setState({
                [category]: {
                    [category]: {
                        ...this.state[category][category],
                        [pageNo]: data
                    },
                    paging
                }
            })
        }
    };

    state = {
        addRecord: this.addRecord,
        purchases: {
            purchases: {
                1: []
            }
        },
        subscriptions: {
            subscriptions: {
                1: []
            }
        },
        transactions: {

        }
    };

    render() {
        return (
            <SalesContext.Provider value={this.state}>
                <Header activeLink={'Sales'} />
                <Suspense fallback={<Loading inline />}>
                    <Switch>
                        <Route path="/sales/purchases" component={Purchases} />
                        <Route path="/sales/subscriptions" component={Subscriptions} />
                        <Route path="/sales/reports" component={Reports} />
                        <Route path="/sales/details/:refno" component={TransactionDetails} />
                    </Switch>
                </Suspense>
            </SalesContext.Provider>
        );
    }
}

export default SalesIndex;