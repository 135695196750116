import React, {Suspense, lazy} from 'react'
import {Route, Switch} from "react-router";
import {BlogEditorProvider} from "../../Contexts/BlogEditorContext";
import {Header} from "../../components/Header";
import Loading from "../../components/Loader/Loading";

const BlogPosts = lazy(() => import("./BlogPosts"));
const EditPost = lazy(() => import("./EditPost"));
const PreviewPost = lazy(() => import("./PreviewPost"));

const BlogIndex = ({match}) => {
    const {path} = match;
    return (
        <BlogEditorProvider>
            <Header activeLink={'Blog'}/>
            <Suspense fallback={<Loading inline/>}>
              <Switch>
                  <Route exact path={path} component={BlogPosts}/>
                  <Route path={`${path}/:postId/edit`} component={EditPost}/>
                  <Route path={`${path}/:postId/preview`} component={PreviewPost}/>
              </Switch>
            </Suspense>
        </BlogEditorProvider>
    )
};

export default BlogIndex