import React from 'react'
import './Avatar.scss'

export const Avatar = ({ bordered = true, imageURL, alt = '', className, ...props }) => (
  <img
    src={imageURL}
    className={`Avatar ${bordered && 'Avatar--bordered'} ${className}`}
    alt={alt}
    {...props}
  />
)
