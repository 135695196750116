import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import './Button.css'

const BaseButton = ({children, onClick, url = null, outline = false, className, danger = false, loaderButton, loading, ...props}) =>
    url ? (
        <Link
            to={url}
            onClick={onClick}
            className={classNames('Button', className)}
            {...props}
        >
            {children}
        </Link>
    ) : (
        <button
            onClick={onClick}
            className={classNames('Button', className,loaderButton && 'Button--loader-button')}
            {...props}
        >
            {
                //Check if it's a loader button and it's loading if it is show the loader else show the text
                (loaderButton && loading)
                    ? <span className={'Button-loader'}/>
                    : children
            }
        </button>
    );

export const SecondaryButton = ({
  children,
  onClick,
  url = null,
  className,
  outline = false,
  loaderButton = false,
  loading = false,
  ...props
}) => (
    <BaseButton
        onClick={onClick}
        url={url}
        className={classNames('Button--secondary', className, outline && 'Button--secondary--outline')}
        outline={outline}
        loaderButton={loaderButton}
        loading={loading}
        {...props}
    >
        {children}
    </BaseButton>
);

export const PrimaryButton = ({
  children,
  onClick,
  url = null,
  className,
  outline = false,
  loaderButton = false,
  loading = false,
  ...props
}) => (
    <BaseButton
        onClick={onClick}
        url={url}
        className={`Button--primary ${className} ${outline && 'Button--primary--outline'}`}
        outline={outline}
        loaderButton={loaderButton}
        loading={loading}
        {...props}
    >
        {children}
    </BaseButton>
);

export const DangerButton = ({
  children,
  onClick,
  url = null,
  className,
  outline = false,
  loaderButton = false,
  loading = false,
  ...props
}) => (
    <BaseButton
        onClick={onClick}
        url={url}
        className={`Button--danger ${className} ${outline && 'Button--danger--outline'}`}
        outline={outline}
        loaderButton={loaderButton}
        loading={loading}
        {...props}
    >
        {children}
    </BaseButton>
);
