import React, {Component} from 'react';
import generalClasses from './ForgotPassword.module.css'
import { TextInput } from "../../components/form/TextInput";
import {PrimaryButton} from "../../components/Button";
import classes from './ResetPassword.module.css'
import StatusCard, {StatusCardStatuses} from "../../components/form/StatusCard";
import {queryToObject} from "./Signup";
import {fetchHelper} from "../../App";
import {Redirect} from "react-router";

class ResetPassword extends Component {
    state = {
        password: '',
        confirmPassword: '',
        error: '',
        loading: false,
        redirect: false,
        status: {
            status: StatusCardStatuses.INACTIVE,
            text: ''
        }
    };

    setStatus = (status,text) => {
        this.setState({
            status: {
                status,
                text
            },
            loading: false
        })
    };

    submit = () => {
        const {password,confirmPassword}  = this.state;
        if(password === '' || confirmPassword === ''){
            this.setStatus(StatusCardStatuses.ERROR, 'Kindly fill all fields.')
        }else if (password !== confirmPassword){
            this.setStatus(StatusCardStatuses.ERROR, 'The passwords do not match')
        }else{
            this.setState({
                loading: true
            });
            const {email,token} = queryToObject(this.props.location.search);
            if(email === ''){
                this.setStatus(StatusCardStatuses.ERROR, 'Email not valid')
            }else if(token === ''){
                this.setStatus(StatusCardStatuses.ERROR, 'Token does not exist')
            }else{
                fetchHelper(`Users/ResetPassword/${email.trim()}/${encodeURIComponent(password)}?token=${token}`,'POST')
                    .then(res => {
                        if(res.status === 200){
                            this.setStatus(StatusCardStatuses.SUCCESS, 'Password successfully reset. Redirecting to login page...');
                            setTimeout(() => {
                                this.setState({
                                    redirect: true
                                })
                            },1000);
                            return 'success'
                        }else{
                            return res.text()
                        }
                    })
                    .then(errText => {
                        if(String(errText) === '"Invalid or expired token"'){
                            this.setStatus(StatusCardStatuses.ERROR,'Invalid or expired token')
                        }else if(String(errText) !== 'success'){
                            this.setStatus(StatusCardStatuses.ERROR,'Something went wrong please try again')
                        }
                    })
                    .catch(() => this.setStatus(StatusCardStatuses.ERROR,'Something went wrong. Kindly check your internet connection and try again'));
            }
        }
    };

    render() {
        return (
            <div className={generalClasses.container}>
                {
                    this.state.redirect && (<Redirect to={'/auth/login'}/>)
                }
                <div className={[generalClasses.form,classes.form].join(' ')}>
                    <StatusCard status={this.state.status.status}>
                        {this.state.status.text}
                    </StatusCard>
                    <TextInput
                        value={this.state.password}
                        onChange={e => {
                            this.setState({password: e.target.value});
                            if(e.target.value === this.state.confirmPassword){
                                this.setState({
                                    error: ''
                                })
                            }
                        }}
                        className={generalClasses.formInput}
                        label={'New Password'}
                        placeholder={'Enter your password'}
                        type={'password'}
                    />
                    <TextInput
                        value={this.state.confirmPassword}
                        onChange={e => {
                            this.setState({confirmPassword: e.target.value});
                            if(this.state.password !== e.target.value){
                                this.setState({
                                    error: 'The passwords do not match'
                                })
                            }else{
                                this.setState({
                                    error: ''
                                })
                            }
                        }}
                        className={generalClasses.formInput}
                        label={'Confirm password'}
                        placeholder={'Enter your password'}
                        error={this.state.error}
                        type={'password'}
                    />
                    <PrimaryButton
                        onClick={this.submit}
                        disabled={this.state.loading}
                        loading={this.state.loading}
                        loaderButton
                    >
                        Confirm
                    </PrimaryButton>
                </div>
            </div>
        );
    }
}

export default ResetPassword;