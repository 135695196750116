export default class Auth {
    static isAuthenticated() {
      const token = localStorage.getItem('token');
      return token ? true : false;
    }
  
    static setToken(token) {
      localStorage.setItem('token', JSON.stringify(token));
    }
  
    static getToken() {
      let token = localStorage.getItem('token');
      return token ? JSON.parse(token) : null;
    }
  
    static removeToken() {
      localStorage.removeItem('token');
    }
  }
  