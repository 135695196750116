import React, {Component} from 'react';
import {NotificationsContext} from "../../Contexts/contexts";
import {withUserDetails} from "../../hoc/WithUserDetails";
import {fetchHelper} from "../../App";
import {makeCachedRequest} from "../../api/api";

class NotificationsProvider extends Component {
    markAsRead = (id) => {
        return fetchHelper(`Notifications/MarkViewed/${id}`, 'PUT').then(res => {
            if (res.status === 200) {
                this.setState({
                    notifications: this.state.notifications.filter(({id: notifId}) => id !== notifId)
                })
            }
        })
    };

    markAllAsRead = () => {
        const notifications = this.state.notifications;
        Promise.all(notifications.map(({id}) => this.markAsRead(id)))
            .then(_ => {
                this.setState({
                    notifications: []
                })
            })
    };

    state = {
        notifications: [],
        initialFetch: false,
        markAsRead: this.markAsRead,
        markAllAsRead: this.markAllAsRead
    };

    fetchNotifications = () => {
        const {userDetails} = this.props;
        makeCachedRequest(`Notifications/UserNotification/${userDetails.email}`)
          .then(res => res.data)
          .then(notifications => {
              return Promise.all([
                  notifications,
                  Promise.all(notifications.map(({id, type}) => {
                      if (type === 'Invite') {
                          return {}
                      } else {
                          return makeCachedRequest(`Notifications/SubjectObject/${id}`).then(res => res.data)
                      }
                  }))
                ])
            })
            .then(([notifications, subjectObject]) => {
                const mappedNotifications = notifications.map((notifications, idx) => ({
                    ...notifications,
                    ...subjectObject[idx],
                    type: notifications.type,
                    notificationType: subjectObject[idx].type || 'course'
                }));
                this.setState({
                    notifications: mappedNotifications
                })
            })
    };

    componentDidMount() {
        if (!this.state.initialFetch) {
            this.fetchNotifications();
            this.setState({
                initialFetch: true
            });
        }
        setInterval(this.fetchNotifications, 300000)
    }

    render() {
        const {children} = this.props;
        return (
            <NotificationsContext.Provider value={this.state}>
                {children}
            </NotificationsContext.Provider>
        );
    }
}


export default withUserDetails(NotificationsProvider);