import React from 'react'
import classes from './LMSErrorBoundary.module.css'
import {PrimaryButton} from "../Button";

export default class LMSErrorBoundary extends  React.Component {
  state = {
    hasError: false
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // TODO: Log errors to a reporting service?
    if (process.env.NODE_ENV === "development")
      console.log(`[LMSErrorBoundary]`, error, errorInfo)
  }

  reloadPage = () => {
    window.location.reload()
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={classes.container}>
          <h1 className={classes.title}>Something went wrong.</h1>
          <p className={classes.subtitle}>We're sorry about that. Our technomancers are hard at work fixing the issue.</p>
          <PrimaryButton className={classes.action} onClick={this.reloadPage}>Reload</PrimaryButton>
        </div>
      )
    }
    return this.props.children
  }
}