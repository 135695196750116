import React from 'react';
import {withUserDetails} from "../../hoc/WithUserDetails";
import {Redirect, Route} from "react-router";

function PrivilegedRoute({path, isStored, component, userDetails, isAdmin, isPrivileged, ...props}) {
    if (isAdmin() || isPrivileged()) {
        return (
            <Route path={path} component={component} {...props}/>
        );
    } else {
        return <Redirect to={'/courses'}/>
    }

}

export default withUserDetails(PrivilegedRoute);