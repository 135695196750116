import React from 'react'
import { Route, Switch } from 'react-router'

import Login from './Login.js'
import SignUp from "./Signup";
import ForgotPassword from "./ForgotPassword";
import './auth.scss'
import ResetPassword from "./ResetPassword";

const AuthScreen = ({match, location}) => (
    <Switch>
        <Route path={`${match.path}/login`} component={Login}/>
        <Route path={`${match.path}/register`} component={SignUp} location={location}/>
        <Route path={`${match.path}/forgot-password`} component={ForgotPassword}/>
        <Route path={`${match.path}/reset-password`} component={ResetPassword}/>
        <Route component={() => <h1>404 Not Found</h1>}/>
    </Switch>
);

export default AuthScreen