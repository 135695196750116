import React from "react";
import classNames from "classnames";
import "./Checkbox.css";

export const Checkbox = ({ checked, onToggle, className, label, ...props }) => (
  <div className={'Checkbox'}>
    <input
        type="checkbox"
        className="Checkbox__check"
        checked={checked}
        onChange={onToggle}
        id={`checkbox-${label}`}
        {...props}
    />
    <label
      htmlFor={`checkbox-${label}`}
      className={classNames("Checkbox__label", className)}
    />
    {label}
  </div>
);
