import React, { Suspense, lazy } from 'react'
import { Header } from "../../components/Header";
import Loading from "../../components/Loader/Loading";
import {Switch} from "react-router";
import PrivateRoute from "../../components/Authentication/PrivateRoute";

const PlansPageBusiness = lazy(() => import("./Business/PlansPageBusiness"));
const PlansPageIndividual = lazy(() => import("./Individual/PlansPageIndividual"));
const PlanForms = lazy(() => import("./PlanForms"));

const SubscriptionsIndex = () => (
  <>
    <Header activeLink={'Subscription'}/>
    <Suspense fallback={<Loading inline/>}>
      <Switch>
        <PrivateRoute path="/plans/business" component={PlansPageBusiness} exact/>
        <PrivateRoute path="/plans/individual" component={PlansPageIndividual} exact/>
        <PrivateRoute path="/plans/:type/:mode" component={PlanForms} exact/>
        <PrivateRoute path="/plans/:type/:mode/:id" component={PlanForms}/>
      </Switch>
    </Suspense>
  </>
)

export default SubscriptionsIndex