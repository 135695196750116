import React, {Suspense, lazy} from "react";
import "./App.css";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import AuthScreen from "./screens/auth/index.jsx";
import SalesIndex from "./screens/Sales/SalesIndex";
import UserIndex from "./screens/Users/UserProfiles/UserIndex";
import UserProvider from "./components/UserProvider/UserProvider";
import AuthCheck from "./components/Authentication/AuthCheck";
import PrivateRoute from "./components/Authentication/PrivateRoute";
import BlogIndex from "./screens/Blog/BlogIndex";
import NotificationsProvider from "./components/NotificationsProvider/NotificationsProvider";
import LMSErrorBoundary from "./components/LMSErrorBoundary/LMSErrorBoundary";
import ClassesIndex from "./screens/Classes/ClassesIndex";
import CoursesIndex from "./screens/Courses/CoursesIndex";
import UsersIndex from "./screens/Users/UsersIndex";
import SubscriptionsIndex from "./screens/Subscriptions/SubscriptionsIndex";
import DashboardIndex from "./screens/Dashboard/DashboardIndex";
import {Header} from "./components/Header";
import Loading from "./components/Loader/Loading";
import Error404 from "./screens/Error404/Error404";
import Auth from "./utils/Auth";

export const API_LINK = process.env.REACT_APP_API_BASE_URL;
export const IMG_LINK = process.env.REACT_APP_API_BASE_URL.replace("/api","");

export const getImageURL = (path, defaultUrl = '') => path ? path : defaultUrl;

const fetchHelper = (route,method = 'GET',body = {}, headers = {}) => {
  if(method === ('GET' || 'DELETE')){
    return fetch(`${API_LINK}/${route}`,{
      method,
      headers: {
        'Authorization': fetchHelper.authorization,
      }
    })
  }else{
    return fetch(`${API_LINK}/${route}`,{
      method,
      body: JSON.stringify(body),
      headers: {
        'Authorization': fetchHelper.authorization,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        ...headers
      }
    })
  }
};
//@todo
//This shouldn't be needed since we have a configureeAuthentication function. This has to be removed. 
//i.e take off fetchHelper. Totally unnecessary using too many http clients
fetchHelper.authorization = `Bearer ${Auth.getToken()}`;

export { fetchHelper }

export const CacheCategories = {
  Course: false,
  Inclass: false,
  Subscriptionplan: false,
  Admin: false,
  Coursecategory: false,
  Blogpost: false,
  Users: false,
  Lessontopic: false,
  Courselesson: false,
  Topiccontent: false
}

const Dashboard = lazy(() => import("./screens/Dashboard/Dashboard"));
const LazyDashboard = () => (
  <>
    <Header activeLink={'Dashboard'} />
    <Suspense fallback={<Loading inline />}>
      <Dashboard />
    </Suspense>
  </>
)

function App() {
  return (
    <LMSErrorBoundary>
      <UserProvider>
        <Router>
          <Switch>
            <Route path="/auth" component={AuthScreen} />
            <AuthCheck>
              <NotificationsProvider>
                <Switch>
                  <PrivateRoute path="/" component={() => <Redirect to="/dashboard" />} exact/>
                  {/* Dashboard is separate due to it not having any child routes */}
                  <PrivateRoute path="/dashboard" component={LazyDashboard} />
                  <Route path="/profile" component={DashboardIndex} />
                  <Route path="/courses" component={CoursesIndex} />
                  <Route path="/blog-posts" component={BlogIndex}/>
                  <Route path="/classes" component={ClassesIndex}/>
                  <PrivateRoute path="/plans" component={SubscriptionsIndex} />
                  <PrivateRoute path="/sales" component={SalesIndex}/>
                  <Route path="/users" component={UsersIndex}/>
                  <Route path="/user/:type/:page/:id" component={UserIndex}/>
                  <Route path="/user/:page/:id" component={UserIndex}/>
                  <Route component={Error404}/>
                </Switch>
              </NotificationsProvider>
            </AuthCheck>
            <Route component={Error404}/>
          </Switch>
        </Router>
      </UserProvider>
    </LMSErrorBoundary>
  );
}

export default App;
