import React from 'react';
import {withUserDetails} from "../../hoc/WithUserDetails";
import {Redirect, Route} from "react-router";

function PrivateRoute({path,isStored,component,userDetails,isAdmin,...props}) {
    if(isAdmin()){
        return (
            <Route path={path} component={component} {...props}/>
        );
    }else{
        return <Redirect to={'/courses'}/>
    }

}

export default withUserDetails(PrivateRoute);