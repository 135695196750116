import React, {Component} from 'react';
import classes from './ForgotPassword.module.css'
import { TextInput } from "../../components/form/TextInput";
import {PrimaryButton, SecondaryButton} from "../../components/Button";
import { Link } from "react-router-dom";
import StatusCard, {StatusCardStatuses} from "../../components/form/StatusCard";
import {fetchHelper} from "../../App";

class ForgotPassword extends Component {
    state = {
        email: '',
        sending: false,
        sent: false,
        status: {
            status: StatusCardStatuses.INACTIVE,
            text: ''
        }
    };

    setStatus = (status,text) => {
        this.setState({
            status: {
                status,
                text
            },
            sending: false
        })
    };

    submit = () => {
        if(this.state.email.trim() === ''){
            this.setStatus(StatusCardStatuses.SUCCESS,'Please enter your email');
        }else{
            this.setState({
                sending: true
            });
            fetchHelper(`Users/SendResetLink/${this.state.email.trim()}`,'POST')
                .then(res => {
                    if(res.status === 200){
                        this.setState({
                            sent: true
                        });
                        return 'success'
                    }else{
                        return res.text()
                    }
                })
                .then(errText => {
                    if(String(errText) === '"User not found"'){
                        this.setStatus(StatusCardStatuses.ERROR,'Account does not exist.');
                    }else if(String(errText) !== 'success'){
                        this.setStatus(StatusCardStatuses.ERROR,'Something went wrong. Please try again');
                    }
                })
                .catch(() => this.setStatus(StatusCardStatuses.ERROR,'Something went wrong. Kindly check your internet connection and try again'));
        }
    };

    render() {
        return (
            <div className={classes.container}>
                {
                    !this.state.sent ?
                        (
                            <div className={classes.form}>
                                <StatusCard status={this.state.status.status}>
                                    {this.state.status.text}
                                </StatusCard>
                                <TextInput
                                    value={this.state.email}
                                    onChange={e => this.setState({email: e.target.value})}
                                    className={classes.formInput}
                                    label={'Email address'}
                                    placeholder={'Enter your email address'}
                                />
                                <PrimaryButton
                                    className={classes.formButton}
                                    loading={this.state.sending}
                                    loaderButton
                                    onClick={this.submit}
                                    disabled={this.state.sending}
                                >
                                    Send details
                                </PrimaryButton>
                                <Link to={'/auth/login'} className={classes.formLink}>
                                    Log In to SME Upturn
                                </Link>
                            </div>
                        ):
                        (
                            <div className={classes.forgotSuccess}>
                                <h3 className={classes.forgotSuccessHeader}>
                                    Hold On
                                </h3>
                                <p className={classes.forgotSuccessText}>
                                    You will receive an email on how to reset your password in few minutes
                                </p>
                                <SecondaryButton
                                    outline
                                    url={'/auth/login'}
                                >
                                    Go Back
                                </SecondaryButton>
                            </div>
                        )
                }

            </div>
        );
    }
}

export default ForgotPassword;