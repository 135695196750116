import React, {Component, Suspense, lazy} from 'react';
import {Redirect, Route} from "react-router";
import PrivateRoute from "../../../components/Authentication/PrivateRoute";
import {withUserDetails} from "../../../hoc/WithUserDetails";
import {Header} from "../../../components/Header";
import Loading from "../../../components/Loader/Loading";

const UserDetails = lazy(() => import("./UserDetails/UserDetails"));
const UserLoyaltyPoints = lazy(() => import("./UserLoyaltyPoints/UserLoyaltyPoints"));
const UserPurchases = lazy(() => import("./UserPurchases/UserPurchases"));
const UserContributions = lazy(() => import("./UserContributions/UserContributions"));
const UserEnrollments = lazy(() => import("./UserEnrollments/UserEnrollments"));

class UserIndex extends Component {
    render() {
        const {isPrivileged,isAdmin} = this.props;
        if(!isAdmin() && !isPrivileged()){
            return (<Redirect to={'/courses'}/>)
        }

        return (
            <>
                <Header activeLink={'Users'} />
                <Suspense fallback={<Loading inline />}>
                    <Route path="/user/:type/details/:id" component={UserDetails}/>
                    <Route path="/user/loyalty-points/:id" component={UserLoyaltyPoints}/>
                    <Route path="/user/purchases/:id" component={UserPurchases}/>
                    <PrivateRoute path="/user/:type/contributions/:id" component={UserContributions}/>
                    <Route path="/user/enrollments/:id" component={UserEnrollments}/>
                </Suspense>
            </>
        );
    }
}
export default withUserDetails(UserIndex);