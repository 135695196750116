import React, {useRef, useState} from 'react'
import './Header.css'
import { HeaderLink } from './HeaderLink.jsx'
import { Avatar } from './Avatar.jsx'
import { ReactComponent as UsersIcon } from "../assets/images/Users.svg";
import { ReactComponent as ClassIcon } from "../assets/images/Class.svg";
import { ReactComponent as CoursesIcon } from "../assets/images/Courses.svg";
import { ReactComponent as BlogIcon } from "../assets/images/Blog.svg";
import { ReactComponent as DashboardIcon } from "../assets/images/Dashboard.svg";
import { ReactComponent as SalesIcon } from "../assets/images/Sales.svg";
import { ReactComponent as SubscriptionsIcon } from "../assets/images/Subscriptions.svg";
import { ReactComponent as DownArrow } from "../assets/images/down-arrow.svg";
import { Link } from "react-router-dom";
import { ReactComponent as LogoutIcon } from "../assets/images/logout.svg";
import { ReactComponent as ProfileIcon } from "../assets/images/user.svg";
import {ReactComponent as BellIcon} from "../assets/images/notifications-bell.svg";
import {ReactComponent as BellIconActive} from "../assets/images/notifications-bell-active.svg";
import {ReactComponent as CancelIcon} from "../assets/images/close.svg";
import {withUserDetails} from "../hoc/WithUserDetails";
import {IMG_LINK} from "../App";
import moment from "moment";
import {withNotifications} from '../hoc/WithNotifications'
import AvatarIcon from "../assets/images/avatar-white.svg";
import {useHideOnClickOutside} from "../hooks/useHideOnClickOutside";
import logo from '../assets/images/new-logo.png';

const links = {
    NewCourse: {
        course: '/courses/edit/id'
  },
    Publish: {
        blogpost: 'blog-posts/id/preview',
        class: 'classes/edit/id',
        course: '/courses/edit/id'
    },
    Purchase: {
        class: '/courses/edit/id',
        course: '/courses/edit/id'
    }
};

let Header = ({activeLink, isAdmin, isPrivileged, userDetails, logOut, notifications, markAsRead, markAllAsRead}) => {
  const adminLinks = [
    {
      title: "Dashboard",
      icon: <DashboardIcon fill="currentColor"/>,
      link: '/dashboard'
    },
    {
      title: "Courses",
      icon: <CoursesIcon fill="currentColor"/>,
      link: '/courses'
    },
    {
      title: "Class",
      icon: <ClassIcon fill="currentColor"/>,
      link: '/classes'
    },
    {
      title: "Users",
      icon: <UsersIcon fill="currentColor"/>,
      link: '/users/students'
    },
    {
      title: "Sales",
      icon: <SalesIcon fill="currentColor"/>,
      link: '/sales/purchases'
    },
    {
      title: "Subscription",
      icon: <SubscriptionsIcon fill="currentColor"/>,
      link: '/plans/individual'
    },
    {
      title: "Blog",
      icon: <BlogIcon fill="currentColor"/>,
      link: '/blog-posts'
    }
  ];
  const authorLinks = [
    {
      title: "Courses",
      icon: <CoursesIcon/>,
      link: '/courses'
    },
    {
      title: "Class",
      icon: <ClassIcon/>,
      link: '/classes'
    },
    {
      title: "Users",
      icon: <UsersIcon/>,
      link: '/users/students'
    },
    {
      title: "Blog",
      icon: <BlogIcon/>,
      link: '/blog-posts'
    }
  ];
  const links = isAdmin() ? adminLinks : authorLinks;
  if(!isAdmin() && !isPrivileged()) {
      links.splice(2, 1);
  }
  const {thumbnail,firstname,lastname} = userDetails;
  const [notificationsActive, toggleNotificationsActive] = useState(false);
  const [linksActive,toggleLinksActive] = useState(false);
  const dropdownRef = useRef(null)
  useHideOnClickOutside({
    open: linksActive,
    hide: () => toggleLinksActive(false),
    el: dropdownRef.current
  })

  const notificationsRef = useRef()
  useHideOnClickOutside({
    open: notificationsActive,
    hide: _ => toggleNotificationsActive(false),
    el: notificationsRef.current
  })

  return (
      <header className="Header">
        <div className="Header__top">
          <div className="container">
            <img src={logo} alt="" className="Header__logo"/>
            <div className="Header__right">
              <div className='Header__notifications-wrapper'>
                <button
                    className='Header__notifications-button'
                    onClick={() => toggleNotificationsActive(!notificationsActive)}
                >
                  {
                    notifications.length > 0 ? (<BellIconActive width={'20px'} fill={'white'}/>) :
                        <BellIcon width={'20px'} fill={'white'}/>
                  }
                </button>
                <div className={[
                  'Header__notifications',
                  notificationsActive ? 'Header__notifications--active' : ''
                ].join(' ')}
                  ref={notificationsRef}
                >
                  {
                    notifications.length > 0 ? (
                        <>
                          <button className='Header__notifications-clear' onClick={markAllAsRead}>
                            Mark all as read
                          </button>
                          {
                            notifications.map((notification) => (
                                <Notification
                                    key={notification.id}
                                            {...notification}
                                            markAsRead={markAsRead}
                                        />
                                    ))
                                }
                        </>
                    ) : (
                        <p className={'Header__notifications-empty'}>
                          Nothing to see here
                        </p>
                    )
                  }
                </div>
              </div>
              <Avatar
                  className="mr-3"
                  imageURL={thumbnail ? thumbnail : AvatarIcon}
              />
              <button className='Header__button' onClick={() => toggleLinksActive(!linksActive)}>
                {firstname} {lastname}
                <DownArrow
                    width={'10px'}
                    fill={'#fff'}
                    style={{
                      marginLeft: '5px'
                    }}
                />
              </button>
              <div ref={dropdownRef} className={[
                'Header__profile-links',
                linksActive && 'Header__profile-links--active'
              ].join(' ')}>
                <Link to={'/profile/account'} className={'Header__profile-link'}>
                  <ProfileIcon className={'Header__profile-link-icon'}/>
                  Account
                </Link>
                <button
                    className={'Header__profile-link'}
                    onClick={() => {
                      logOut()
                    }}
                >
                  <LogoutIcon className={'Header__profile-link-icon'}/>
                  Log out
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="Header__bottom">
          <div
            className={[
              "container",
              links.length !== 7 && 'Header__container-grid'
            ].join(' ')}
            style={{
              gridTemplateColumns: `repeat(${links.length},1fr)`
            }}
          >
            {
              links.map(({ title, icon, link }) => (
                  <HeaderLink title={title} active={title === activeLink} key={title} url={link}
                              className={links.length !== 7 && 'Header__link--grid'}>
                    {icon}
                  </HeaderLink>
              ))
            }
          </div>
        </div>
      </header>
  )
};

const Notification = ({id, remark, type, notedate, notificationType, object, markAsRead}) => (
    <div className={'Header__notification'}>
        {
            type === 'Invite' ? (
                <div className={'Header__notification-link'}>
              <span>
                {remark}
              </span>
                    <span className='Header__notification-date'>
                {
                    moment(notedate).format('MMM D, HH:mm')
                }
              </span>
                </div>
            ) : (
                <Link className={'Header__notification-link'}
                      to={`${links[type][notificationType].replace('id', object)}`}
                      onClick={() => markAsRead(id)}
                >
                  <span>
                    {remark}
                  </span>
                  <span className='Header__notification-date'>
                  {
                    moment(notedate).format('MMM D, HH:mm')
                  }
                  </span>
                </Link>
            )
        }
        <button className='Header__notification-mark-read' onClick={() => markAsRead(id)}>
            <CancelIcon fill={'#b5b7bf'} width={'8px'}/>
        </button>
    </div>
);

Header = withUserDetails(withNotifications(Header));

export {Header};