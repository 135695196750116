import React, {Component} from 'react';
import {UserDetailsContext} from "../../Contexts/contexts";
import moment from "moment";
import {makeCachedRequest} from "../../api/api";
import {getErrorMessage} from "../../utils/functions";

class UserProvider extends Component {
    isLoggedIn = () => this.state.loggedIn;

    isAdmin = () => this.state.userDetails.role === 'Admin';

    logOut = () => {
        localStorage.setItem('user', null)
        this.setState({loggedIn: false, userDetails: {}});
    };

    logIn = (userDetails,stayLoggedIn) => {
        this.setState({userDetails, loggedIn: true});
        const expires = moment();
        if (stayLoggedIn) {
            expires.add(1, 'week')
        } else {
            expires.add(1, 'day')
        }

        localStorage.setItem('user', JSON.stringify({
            email: userDetails.email,
            expires
        }))
    };

    updateUser = (userDetails) => this.setState({userDetails});

    isPrivileged = () => this.state.userDetails.isprivileged === 1;

    getUser = (email) => {
        const users = makeCachedRequest(`Users/${email}`).then(res => res.data)
        const thumbnail = makeCachedRequest(`Users/GetDp/${email.trim()}`)
          .then(res => res.data)
          .catch(err => {
              if (err.response && err.response.status === 404) {
                  return null
              }

              return Promise.reject(getErrorMessage(err))
          });
        return Promise.all([users, thumbnail]).then(([user, thumbnail]) => this.logIn({...user, thumbnail}));
    };

    isStored = () => {
        try {
            if (!this.state.loggedIn) {
                const user = localStorage.getItem('user');
                if (!user || user === 'null') {
                    return false;
                }

                const userData = JSON.parse(user);
                const {email, expires} = userData;
                if (moment(expires).isBefore(new Date())) {
                    localStorage.setItem('user', null);
                    return false
                } else {
                    return this.getUser(email)
                }
            }
        } catch (e) {
            localStorage.setItem('user', null)
            return false
        }
    };

    state = {
        isLoggedIn: this.isLoggedIn,
        isAdmin: this.isAdmin,
        logOut: this.logOut,
        logIn: this.logIn,
        loggedIn: false,
        isPrivileged: this.isPrivileged,
        updateUser: this.updateUser,
        userDetails: {},
        isStored: this.isStored
    };

    render() {
        let {children} = this.props;
        return (
            <UserDetailsContext.Provider value={this.state}>
                {children}
            </UserDetailsContext.Provider>
        );
    }
}

export default UserProvider;