import React, { Suspense, lazy } from 'react'
import { Header } from "../../components/Header";
import Loading from "../../components/Loader/Loading";
import {Switch} from "react-router";
import PrivilegedRoute from "../../components/Authentication/PrivilegedRoute";
import PrivateRoute from "../../components/Authentication/PrivateRoute";
const Students = lazy(() => import("./Students/Students"));
const Owners  = lazy(() => import("./Owners/Owners"));
const Authors = lazy(() => import("./Authors/Authors"));

const UsersIndex = () => (
  <>
    <Header activeLink={'Users'} />
    <Suspense fallback={<Loading inline />}>
      <Switch>
        <PrivilegedRoute path="/users/students" component={Students}/>
        <PrivateRoute path="/users/owners" component={Owners}/>
        <PrivateRoute path="/users/authors" component={Authors}/>
      </Switch>
    </Suspense>
  </>
);

export default UsersIndex;