import React, { Suspense, lazy } from 'react'
import { Header } from "../../components/Header";
import Loading from "../../components/Loader/Loading";
import {Route, Switch} from "react-router";

const Account = lazy(() => import("./Account"));
const ChangePassword = lazy(() => import("./ChangePassword"));

const DashboardIndex = () => {
  return (
    <>
      <Header activeLink={'Dashboard'} />
      <Suspense fallback={<Loading inline />}>
        <Switch>
          <Route path="/profile/account" component={Account}/>
          <Route path="/profile/change-password" component={ChangePassword}/>
        </Switch>
      </Suspense>
    </>
  )
}

export default DashboardIndex