import React, {Component} from 'react';
import classes from "./Login.module.css";
import {TextInput} from "../../components/form/TextInput";
import {fetchHelper} from "../../App";
import {withUserDetails} from "../../hoc/WithUserDetails";
import {Redirect} from "react-router";
import {PrimaryButton} from "../../components/Button";
import StatusCard, {StatusCardStatuses} from "../../components/form/StatusCard";
import Logo from "../../assets/images/new-logo.png";
import {Link} from "react-router-dom";

export const queryToObject = (query) => {
    return query.slice(1).split('&').reduce((obj,curr) => ({
        ...obj,
        [curr.split(/(?<=.+)\=(?=.+)/)[0]]: curr.split(/(?<=.+)\=(?=.+)/)[1]
    }),{})
};

class SignUp extends Component {
    state = {
        name: '',
        password: '',
        retypePassword: '',
        email: '',
        redirect: false,
        redirectLink: '',
        submitting: false,
        error: '',
        status: {
            status: StatusCardStatuses.INACTIVE,
            text: ''
        }
    };

    componentDidMount() {
        const {location} = this.props;
        const {firstname = '',lastname = '',email} = queryToObject(location.search);
        this.setState({
            name: `${firstname} ${lastname}`.trim(),
            email
        })
    }

    verify = () => {
        const {password,retypePassword,email,name} = this.state;
        return ![password,retypePassword,email,name].some(val => val === '');
    };

    setStatus = (status,text) => {
        this.setState({
            status: {
                status,
                text
            },
            submitting: false
        })
    };

    submit = () => {
        this.setState({
            submitting: true
        });
        const [firstname,lastname] = this.state.name.split(' ');
        const {password,retypePassword,email} = this.state;
        if(this.verify()){
            if (password !== retypePassword) {
                this.setStatus(StatusCardStatuses.ERROR, 'The passwords do not match')
            } else if (!email) {
                this.setStatus(StatusCardStatuses.ERROR, 'Email does not exist')
            } else {
                fetchHelper(`Users/Signup/${email.trim()}/${firstname.trim()}/${lastname.trim()}/${password}`, 'POST')
                    .then(res => {
                        if (res.status === 200) {
                            this.setStatus(StatusCardStatuses.SUCCESS, 'Sign up successful. Redirecting to login page.');
                            this.setState({
                                redirectLink: '/auth/login'
                            });
                            setTimeout(() => {
                                this.setState({
                                    redirect: true
                                })
                            },2000);
                            return 'success'
                        }else{
                            return res.text()
                        }
                    })
                    .then(errText => {
                        if(String(errText)  === '"User already completed signup"'){
                            this.setStatus(StatusCardStatuses.ERROR,'User already exists. Kindly go to the login page')
                        }else if(String(errText) === '"User not found"'){
                            this.setStatus(StatusCardStatuses.ERROR,'User has not been invited. Kindly verify email.')
                        }else if(String(errText) !== 'success'){
                            this.setStatus(StatusCardStatuses.ERROR, 'Something went wrong. Kindly try again')
                        }
                    })
                    .catch(() => {
                        this.setState({
                            status: {
                                status: StatusCardStatuses.ERROR,
                                text: 'Kindly check your internet connection and try again'
                            },
                            loggingIn: false
                        })
                    })
            }
        }
        else{
            this.setStatus( StatusCardStatuses.ERROR,'Kindly fill all fields')
        }
    };

    render() {
        return (
            <>
                {
                    this.state.redirect && <Redirect to={this.state.redirectLink}/>
                }
                <div className={classes.container}>
                    <div className={[classes.image, classes.registerImage].join(' ')}>

                    </div>
                    <div className={[
                        classes.form,
                        classes.registerForm
                    ].join(' ')}>
                        <img src={Logo} className={classes.formIcon}/>
                        <p className={classes.formHeader}>
                            Sign Up
                        </p>
                        <StatusCard status={this.state.status.status}>
                            {this.state.status.text}
                        </StatusCard>
                        <div className={classes.formGroup}>
                            <TextInput
                                value={this.state.email || 'No email'}
                                label={'Email'}
                                disabled
                                className={classes.formInput}
                            />
                        </div>
                        <div className={classes.formGroup}>
                            <TextInput
                                value={this.state.name}
                                label={'Full Name'}
                                placeholder={'Enter your fullname'}
                                onChange={e => this.setState({name: e.target.value})}
                                className={classes.formInput}
                            />
                        </div>
                        <div className={classes.formGroup}>
                            <TextInput
                                value={this.state.password}
                                label={'Password'}
                                placeholder={'Enter your password'}
                                onChange={e => {
                                    this.setState({password: e.target.value});
                                    if(e.target.value === this.state.retypePassword) {
                                        this.setState({
                                            error: ''
                                        })
                                    }
                                }}
                                className={classes.formInput}
                                type={'password'}
                            />
                        </div>
                        <div className={classes.formGroup}>
                            <TextInput
                                value={this.state.retypePassword}
                                label={'Re-type Password'}
                                placeholder={'Enter your password'}
                                onChange={e => {
                                    this.setState({retypePassword: e.target.value});
                                    if(e.target.value !== this.state.password){
                                        this.setState({
                                            error: 'The passwords do not match'
                                        })
                                    }else{
                                        this.setState({
                                            error: ''
                                        })
                                    }
                                }}
                                className={classes.formInput}
                                type={'password'}
                                error={this.state.error}
                            />
                        </div>
                        <PrimaryButton
                            className={classes.formButton}
                            onClick={this.submit}
                            loaderButton
                            loading={this.state.submitting}
                        >
                            Sign Up
                        </PrimaryButton>
                        <Link to={'/auth/login'} className={classes.formLink}>
                            Log In
                        </Link>
                    </div>
                </div>
            </>
        );
    }
}

export default withUserDetails(SignUp);