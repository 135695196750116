import React, {useContext} from "react";

export const SalesContext = React.createContext({});

export const LessonContext = React.createContext({});

export const UserDetailsContext = React.createContext({});

export const useUserDetails = () => useContext(UserDetailsContext);

export const NotificationsContext = React.createContext({});
