import axios from 'axios'
import { setup } from "axios-cache-adapter";
import { CacheCategories, fetchHelper } from "../App";
import Auth from '../utils/Auth';

const API_LINK = process.env.REACT_APP_API_BASE_URL;

const makeRequest = axios.create({
    baseURL: API_LINK
})


export const minutesToMilliseconds = minutes => minutes * 60 * 1000

const makeCachedRequest = setup({
    baseURL: API_LINK,
    cache: {
        maxAge: minutesToMilliseconds(15),
        invalidate: async (config, request) => {
            const promises = []
            Object.entries(CacheCategories).forEach(([category, value]) => {
                if (value) {
                    Object.keys(config.store.store).forEach((storeKey) => {
                        if (storeKey.startsWith(category)) {
                            promises.push(config.store.removeItem(storeKey))
                        }
                    })
                    CacheCategories[category] = false
                }
            })

            await Promise.all(promises)

            if (request.clearCacheEntry) {
                await config.store.removeItem(config.uuid)
            }
        },
        exclude: { query: false },
    }
})

export { makeRequest, makeCachedRequest }

//configureAuthentication configures the authentication of the app
//basically we configure authentication when the app loads, during login and register
//@todo: Its important to save token Inmemory, current impl is susceptible to xss attack
export const configureAuthentication = token => {
    let _token = token || Auth.getToken()
    makeRequest.defaults.headers.common['Authorization'] = `Bearer ${_token}`
    makeCachedRequest.defaults.headers.common[
        'Authorization'
    ] = `Bearer ${_token}`
    Auth.setToken(_token)
}

configureAuthentication()