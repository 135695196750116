import {makeCachedRequest} from "./api";
import {CartItemTypes} from "../utils/constants";

export const fetchItemsData = async items => {
    return Promise.all(
        items.map(({orderType, orderTypeId}) => {
            if (orderType === CartItemTypes.Plan) {
                return fetchPlan(orderTypeId)
            } else if (orderType === CartItemTypes.Course) {
                return fetchCourseDetails(orderTypeId)
            } else {
                return fetchClassDetails(orderTypeId)
            }
        })
    )
}

export async function fetchPlan(planId) {
    const response = await makeCachedRequest.get(`Subscriptionplan/${planId}`)
    return response.data
}

export const fetchClassDetails = async id => {
    const classRes = await makeCachedRequest(`Inclass/${id}`)
    return classRes.data
}

export const fetchCourseDetails = async coursecode => {
    const courseRes = await makeCachedRequest(`Course/${coursecode}`)
    return courseRes.data
}